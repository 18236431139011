import {
  Box,
  Button,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useParticipant } from '@videosdk.live/react-sdk'
import React, { useEffect } from 'react'
import { BiLogOut, BiSolidUser } from 'react-icons/bi'
import {
  BsCameraVideo,
  BsCameraVideoOff,
  BsMic,
  BsMicMute,
} from 'react-icons/bs'
import { useAuth } from '../../../hooks/useAuth'

function UserCard({ user }) {
  const { isAdmin, user: loggedUser } = useAuth()

  const {
    micOn,
    remove,
    displayName,
    participant,
    enableWebcam,
    webcamOn,
    disableWebcam,
    setQuality,
    pin,
    unpin,
  } = useParticipant(user.id)

  useEffect(() => {
    if (participant.micOn) {
      pin('SHARE_AND_CAM')
      setQuality('high')
    } else {
      setQuality('low')
      unpin('SHARE_AND_CAM')
    }
  }, [participant.micOn])

  return (
    <HStack
      pb='10px'
      key={String(user.userId)}
      justify='space-between'
      spacing='14px'
    >
      <Stack
        align='center'
        justify='center'
        w='45px'
        h='45px'
        borderRadius='50px'
        bg='gray.100'
      >
        <Icon as={BiSolidUser} color='gray.400' fontSize='22px' />
      </Stack>

      <Box flex={1}>
        {isAdmin && (
          <Text fontSize='12px' fontWeight='bold' color='rgba(223, 145, 38, 1)'>
            Organizador
          </Text>
        )}

        <Text fontSize='14px'>{displayName}</Text>
      </Box>

      {isAdmin && (
        <HStack justify='center' pt='10px'>
          <Button
            bg={!micOn ? 'white' : 'rgba(223, 145, 38, 1)'}
            borderRadius='50px'
            w='40px'
            h='40px'
            p='0'
            isDisabled={loggedUser?.id !== Number(user?.id) && !micOn}
            onClick={() => {
              if (micOn) {
                participant.disableMic()
              } else {
                participant.enableMic()
              }
            }}
          >
            {micOn ? (
              <BsMic color='white' size={18} />
            ) : (
              <BsMicMute size={18} />
            )}
          </Button>

          <Button
            bg={!webcamOn ? 'white' : 'rgba(223, 145, 38, 1)'}
            borderRadius='50px'
            w='40px'
            h='40px'
            p='0'
            isDisabled={loggedUser?.id !== Number(user?.id) && !webcamOn}
            onClick={() => {
              if (webcamOn) {
                disableWebcam()
              } else {
                enableWebcam()
              }
            }}
          >
            {webcamOn ? <BsCameraVideo color='white' /> : <BsCameraVideoOff />}
          </Button>

          {loggedUser?.id !== Number(user?.id) && (
            <Tooltip label='Remover usuário da chamada'>
              <Button
                bg='red'
                borderRadius='50px'
                w='40px'
                h='40px'
                p='0'
                onClick={remove}
              >
                <BiLogOut color='white' />
              </Button>
            </Tooltip>
          )}
        </HStack>
      )}
    </HStack>
  )
}

export default UserCard
