import React, { useEffect } from 'react'
import {
  Box,
  Center,
  Flex,
  Input,
  Spinner,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Table,
} from '@chakra-ui/react'
import { delay } from 'lodash'

import { useMeeting } from '@videosdk.live/react-sdk'
import { useCredores } from '../../../../hooks/useCredores'
import CardCredor from '../../card-credor'
import { useDialog } from '../../../../hooks/useDialog'
import { useAuth } from '../../../../hooks/useAuth'

function CredoresOnline({ isOpen }) {
  const { callId, user, isAdmin } = useAuth()
  const showDialog = useDialog()
  const { participants } = useMeeting()
  const users = [...participants.values()]

  const {
    filters,
    getCredores,
    credores,
    updateCredor,
    loading,
    deleteCredor,
    setFilters,
  } = useCredores(Number(callId))

  useEffect(() => {
    if (isOpen) {
      getCredores()
    }
  }, [isOpen, filters])

  useEffect(() => {
    if (!isAdmin) {
      setFilters({
        ...filters,
        usuario_id: user?.id,
        limit: 1000,
      })
    } else {
      setFilters({
        ...filters,
        limit: 1000,
      })
    }
  }, [user, isAdmin])

  const deleteCredorDialog = (id) => {
    showDialog({
      title: 'Excluir credor',
      message: 'Deseja realmente excluir este credor?',
      buttonCancel: 'Cancelar',
      buttonOK: 'Excluir',
      onClick: () => {
        deleteCredor(id)
      },
    })
  }

  const checkIsIncludedOnUsers = credores.filter((credor) => {
    const usersFounded = users.filter(
      (user) =>
        !!credor?.procuradores?.find(
          (procurador) =>
            String(procurador?.procurador_nome) === String(user?.displayName)
        )
    )
    return usersFounded.length > 0
  })

  return (
    <Flex flex={1} flexDir='column'>
      <Box pb='20px'>
        <Input
          size='sm'
          w='full'
          bg='white'
          color='gray.600'
          placeholder='Pesquisar credor'
          onChange={async (e) => {
            delay(
              () =>
                setFilters({
                  ...filters,
                  search: e.target.value,
                }),
              500
            )
          }}
        />
      </Box>

      {loading && (
        <Center w='full' py='30px'>
          <Spinner />
        </Center>
      )}

      {!loading && (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>nome</Th>
                <Th>CNPJ / CPF</Th>
                <Th>classe</Th>
                <Th isNumeric>moeda</Th>
                <Th isNumeric>credito</Th>
                <Th isNumeric>ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {checkIsIncludedOnUsers.map((credor) => (
                <CardCredor
                  updateCredor={updateCredor}
                  credor={credor}
                  key={String(credor.id)}
                  deleteCredor={deleteCredorDialog}
                />
              ))}

              {checkIsIncludedOnUsers.length === 0 && (
                <Tr>
                  <Th opacity={0.5} pt='30px' colSpan={6} textAlign='center'>
                    Nenhum credor encontrado
                  </Th>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  )
}

export default CredoresOnline
