import * as React from 'react'

function MicOffIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.835 7.607h-1.417c.003.594-.12 1.18-.363 1.721l1.027 1.033c.49-.834.75-1.785.753-2.754Zm-3.346.138c0-.045.012-.092.012-.138V2.573a2.537 2.537 0 0 0-.713-1.816A2.5 2.5 0 0 0 9 0a2.484 2.484 0 0 0-1.787.757 2.522 2.522 0 0 0-.713 1.816v.153l4.988 5.02ZM2.562.895 1.5 1.963l5.009 5.043v.61c0 .665.262 1.304.73 1.776A2.484 2.484 0 0 0 9 10.129c.182-.003.364-.025.542-.067l1.383 1.392c-.603.283-1.26.43-1.925.432a4.294 4.294 0 0 1-3.088-1.216 4.357 4.357 0 0 1-1.325-3.063H3.167a5.88 5.88 0 0 0 1.475 3.742 5.797 5.797 0 0 0 3.525 1.897v2.747h1.666v-2.746a5.88 5.88 0 0 0 2.117-.76L15.438 16l1.062-1.066L2.562.894Z'
        fill='#9E9EA7'
      />
    </svg>
  )
}

export default MicOffIcon
