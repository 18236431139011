import { MeetingProvider } from '@videosdk.live/react-sdk'
import { useEffect, useState } from 'react'
import {
  useDisclosure,
  HStack,
  Box,
  Stack,
  Text,
  Center,
  Spinner,
} from '@chakra-ui/react'
import moment from 'moment'
import { MeetingAppProvider } from './MeetingAppContextDef'
import { MeetingContainer } from './meeting/MeetingContainer'
// import { LeaveScreen } from './components/screens/LeaveScreen'
import { JoiningScreen } from './components/screens/JoiningScreen'
import HeaderAGC from './agc/modules/header'
import LeftMenu from './agc/modules/left-menu'
import PeopleAndChat from './agc/modules/people-chats'
import DocsTabComponent from './agc/modules/Docs'
import { useAuth } from './agc/hooks/useAuth'
import PainelVotacao from './agc/modules/Votacao'
import { useWebsocket } from './agc/hooks/useWebsocket'
import { useAGC } from './agc/hooks/useAGC'
import { CredoresModal } from './agc/modules/Credores/credores-modal'
import { CredenciamentoModal } from './agc/modules/Credores/credenciamento-modal'
import ModalVotar from './agc/modules/Votacao/ModalVotar'
import SuspenderModal from './agc/modules/SuspenderModal'

function VideoSDKAPP() {
  const { user, callId, isAdmin } = useAuth()
  const [token, setToken] = useState('')
  const [meetingId, setMeetingId] = useState('')
  const [micOn, setMicOn] = useState(true)
  const [webcamOn, setWebcamOn] = useState(true)
  const [selectedMic, setSelectedMic] = useState({ id: null })
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null })
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  )
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id)
  const [isMeetingStarted, setMeetingStarted] = useState(false)
  const [isMeetingLeft, setIsMeetingLeft] = useState(false)

  const [tab, setTab] = useState('principal')

  const isMobile = window.matchMedia(
    'only screen and (max-width: 768px)'
  ).matches

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => 'Você tem certeza que quer sair?'
    }
  }, [isMobile])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { iniciarPerguntas, suspensao } = useWebsocket()
  const { listarVotantes, entrar } = useAGC(Number(callId))

  useEffect(() => {
    if (callId) {
      entrar()
    }
  }, [callId])

  useEffect(() => {
    if (iniciarPerguntas?.id) {
      listarVotantes(iniciarPerguntas?.id).then((votantes) => {
        if ((votantes ?? []).includes(Number(user?.id))) {
          onOpen()
        }
      })
    }
  }, [iniciarPerguntas, user])

  const {
    isOpen: isCredoresModalOpen,
    onOpen: onCredoresModalOpen,
    onClose: onCredoresModalClose,
  } = useDisclosure()

  const {
    isOpen: isCredenciamentoModalOpen,
    onOpen: onCredenciamentoModalOpen,
    onClose: onCredenciamentoModalClose,
  } = useDisclosure()

  const {
    isOpen: isSuspenderModalOpen,
    onOpen: onSuspenderModalOpen,
    onClose: onSuspenderModalClose,
  } = useDisclosure()

  const [isSupensed, setIsSuspensed] = useState(false)

  const activeSuspensao = suspensao?.find((item) =>
    moment().isBefore(moment(item.data_retomada).add(3, 'hours'))
  )

  useEffect(() => {
    if (activeSuspensao) {
      setIsSuspensed(true)
    }
  }, [activeSuspensao])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isSus = suspensao?.find((item) =>
        moment().isBefore(moment(item.data_retomada).add(3, 'hours'))
      )
      setIsSuspensed(!!isSus)
    }, 5000)
    return () => clearInterval(intervalId)
  }, [suspensao])

  useEffect(() => {
    if (isMeetingLeft) {
      // eslint-disable-next-line no-restricted-globals
      history.back()
      // eslint-disable-next-line no-restricted-globals
      history.back()
    }
  }, [isMeetingLeft])

  if (!user?.id) {
    return (
      <Center h='100vh' w='100vw'>
        <Spinner />
      </Center>
    )
  }

  return (
    <div>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={!!isAdmin && !isSupensed}
          initialWebcamOn={!!isAdmin && !isSupensed}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: !!isAdmin && !isSupensed,
              webcamEnabled: !!isAdmin && !isSupensed,
              name: `${user?.nome} ${user?.sobrenome}`,
              participantId: user?.id,
              metaData: {
                user: `${user?.nome} ${user?.sobrenome}`,
                callId,
                isAdmin,
              },
              mode: 'CONFERENCE',
            }}
            token={token}
            reinitialiseMeetingOnConfigChange
            joinWithoutUserInteraction
          >
            <HeaderAGC
              callId={callId}
              isAdmin={isAdmin}
              onSuspenderModalOpen={onSuspenderModalOpen}
              isMeetingStarted={isMeetingStarted}
              setIsMeetingLeft={setIsMeetingLeft}
            />

            <HStack h='calc(100vh - 70px)' w='full' bg='red' spacing={0}>
              <Stack
                display={!isSupensed ? 'none' : 'flex'}
                align='center'
                justify='center'
                bg='gray.300'
                flex={1}
                h='calc(100vh - 70px)'
                w='full'
              >
                <Text fontSize='30px'>AGC Suspensa</Text>

                <Text fontSize='16px'>
                  Voltamos{' '}
                  {moment(activeSuspensao?.data_retomada)
                    .add(3, 'hours')
                    .format('LLLL')}
                </Text>
              </Stack>

              <Box flex='1' display={isSupensed ? 'none' : 'flex'}>
                <MeetingContainer
                  onMeetingLeave={() => {
                    setToken('')
                    setMeetingId('')
                    setWebcamOn(false)
                    setMicOn(false)
                    setMeetingStarted(false)
                    setIsMeetingLeft(true)
                  }}
                  setIsMeetingLeft={setIsMeetingLeft}
                  selectedMic={selectedMic}
                  selectedWebcam={selectedWebcam}
                  selectWebcamDeviceId={selectWebcamDeviceId}
                  setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                  selectMicDeviceId={selectMicDeviceId}
                  setSelectMicDeviceId={setSelectMicDeviceId}
                  micEnabled={!!isAdmin && !isSupensed}
                  webcamEnabled={!!isAdmin && !isSupensed}
                />
              </Box>

              {tab === 'votacao' && <PainelVotacao />}

              {tab === 'principal' && <PeopleAndChat isAdmin={isAdmin} />}

              {tab === 'docs' && <DocsTabComponent agcId={callId} />}

              {isOpen && (
                <ModalVotar
                  perguntaId={iniciarPerguntas?.id}
                  isOpen={isOpen}
                  onClose={onClose}
                />
              )}

              <SuspenderModal
                isOpen={isSuspenderModalOpen}
                onClose={onSuspenderModalClose}
              />

              <CredoresModal
                isOpen={isCredoresModalOpen}
                onClose={onCredoresModalClose}
              />

              <CredenciamentoModal
                isOpen={isCredenciamentoModalOpen}
                onClose={onCredenciamentoModalClose}
              />

              <LeftMenu
                setTab={(currentTab) => {
                  if (currentTab === 'credores') {
                    return onCredoresModalOpen()
                  }
                  if (currentTab === 'credenciamento') {
                    return onCredenciamentoModalOpen()
                  }

                  setTab(currentTab)
                }}
                tab={tab}
              />
            </HStack>
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? null : (
        <JoiningScreen
          userId={user?.id}
          userIsAdmin={isAdmin}
          meetId={callId}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => {
            setMeetingStarted(true)
          }}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
        />
      )}
    </div>
  )
}

export default VideoSDKAPP
