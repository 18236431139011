import * as React from 'react'

function MicOnIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.92 10.11a2.525 2.525 0 0 0 2.531-2.525l.008-5.048a2.537 2.537 0 1 0-5.074 0v5.048a2.531 2.531 0 0 0 2.535 2.524Zm4.482-2.525a4.373 4.373 0 0 1-4.482 4.29 4.373 4.373 0 0 1-4.483-4.29H3a5.88 5.88 0 0 0 5.075 5.654V16h1.692v-2.761a5.88 5.88 0 0 0 5.074-5.654h-1.439Z'
        fill='#fff'
      />
    </svg>
  )
}

export default MicOnIcon
