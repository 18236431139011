import React, { useEffect } from 'react'
import {
  Center,
  Flex,
  Spinner,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Table,
} from '@chakra-ui/react'

import { useCredores } from '../../../../hooks/useCredores'
import CardCredor from '../../card-credor'
import { useAuth } from '../../../../hooks/useAuth'
import { useDialog } from '../../../../hooks/useDialog'

function CredoresHabilitados({ isOpen }) {
  const { callId } = useAuth()
  const showDialog = useDialog()

  const {
    getCredoresHabilitados,
    credoresHabilitados,
    deleteCredor,
    updateCredor,
    loading,
  } = useCredores(Number(callId))

  useEffect(() => {
    if (isOpen) {
      getCredoresHabilitados()
    }
  }, [isOpen])

  const deleteCredorDialog = (id) => {
    showDialog({
      title: 'Excluir credor',
      message: 'Deseja realmente excluir este credor?',
      buttonCancel: 'Cancelar',
      buttonOK: 'Excluir',
      onClick: () => {
        deleteCredor(id)
      },
    })
  }

  return (
    <Flex flex={1} flexDir='column'>
      {loading && (
        <Center w='full' py='30px'>
          <Spinner />
        </Center>
      )}

      {!loading && (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>nome</Th>
                <Th>CNPJ / CPF</Th>
                <Th>classe</Th>
                <Th isNumeric>moeda</Th>
                <Th isNumeric>credito</Th>
                <Th isNumeric>ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {credoresHabilitados.map((credor) => (
                <CardCredor
                  updateCredor={updateCredor}
                  credor={credor}
                  key={String(credor.id)}
                  deleteCredor={deleteCredorDialog}
                />
              ))}

              {credoresHabilitados.length === 0 && (
                <Tr>
                  <Th opacity={0.5} pt='30px' colSpan={6} textAlign='center'>
                    Nenhum credor encontrado
                  </Th>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  )
}

export default CredoresHabilitados
