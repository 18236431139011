import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useWebsocket } from '../../hooks/useWebsocket'
import InputComponent from '../../components/input'

function SuspenderModal({ isOpen, onClose }) {
  const formMethods = useForm({})
  const { suspenderAgc } = useWebsocket()

  const handlerSubmit = (values) => {
    const newValues = {
      ...values,
      data_suspensao: `${values.data_suspensao}:00.000Z`,
      data_retomada: `${values.data_retomada}:00.000Z`,
    }
    suspenderAgc(newValues)
    onClose()
  }

  return (
    <Modal size='2xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg='blackAlpha.600' backdropFilter='blur(10px)' />

      <ModalContent>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handlerSubmit)}>
            <ModalHeader>
              Selecione o tempo de suspensão da assembleia
            </ModalHeader>

            <ModalBody p='50px 10px'>
              <HStack px='10px'>
                <InputComponent
                  name='data_suspensao'
                  label='Data da suspensão'
                  placeholder='Data da suspensão'
                  type='datetime-local'
                  bg='gray.100'
                  border='2px solid #ddd'
                  flex={1}
                />
                <InputComponent
                  name='data_retomada'
                  label='Data da retomada'
                  placeholder='Data da retomada'
                  type='datetime-local'
                  bg='gray.100'
                  border='2px solid #ddd'
                  flex={1}
                />
              </HStack>
            </ModalBody>

            <ModalFooter>
              <Button
                mr='20px'
                borderColor='#f39c12'
                variant='outline'
                borderWidth='2px'
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                bg='#f39c12'
                color='white'
                fontWeight='bold'
                type='submit'
              >
                Suspender
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}

export default SuspenderModal
