import React, { createContext, useContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { useSuspenderAGC } from './useSuspender'

const WebSocketContext = createContext(null)
const socket = io('wss://socket.e-agc.com.br')

export function WebsocketProvider({ children, callId }) {
  const {
    getSuspensao,
    suspenderAgc: callApiSuspender,
    suspensao,
  } = useSuspenderAGC()

  const [iniciarPerguntas, setIniciarPerguntas] = useState({
    id: undefined,
  })

  useEffect(() => {
    getSuspensao(callId)
    socket?.on('connect', () => {
      console.log('websocket agc connected ', socket.connected)
    })

    socket?.on(`suspenderAgc:${callId}`, () => {
      getSuspensao(callId)
    })

    socket?.on(`dispararPerguntas:${callId}`, (valor) => {
      setIniciarPerguntas(valor || false)
    })

    socket?.on('disconnect', () => {
      console.log('websocket agc disconnected ', !socket.connected)
    })
  }, [callId])

  const dispararPerguntas = async (id) => {
    socket?.emit('dispararPerguntas', {
      id: callId,
      payload: {
        id,
      },
    })
  }

  const suspenderAgc = async (data) => {
    callApiSuspender({ ...data, agc_id: callId })
    socket?.emit('suspenderAgc', {
      id: callId,
      payload: data,
    })
  }

  return (
    <WebSocketContext.Provider
      value={{
        dispararPerguntas,
        iniciarPerguntas,
        setIniciarPerguntas,
        suspenderAgc,
        suspensao,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebsocket = () => {
  const context = useContext(WebSocketContext)

  if (!context) {
    throw new Error('useWebsocket must be used within an WebsocketProvider')
  }

  return context
}
