export const buttonStyle = {
  baseStyle: {
    fontSize: '16px',
    fontWeight: '400',
    paddingY: '17px',
    paddingX: '30px',
    borderRadius: '43px',
    boxShadow: '0px 15px 24px 0px rgba(0, 0, 0, 0.05)',
    outline: 0,
  },
  defaultProps: {
    size: 'lg',
    variant: 'solid',
  },
}
