/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
export const json_verify = (s) => {
  try {
    JSON.parse(s)
    return true
  } catch (e) {
    return false
  }
}

export function getQualityScore(stats) {
  const packetLossPercent = stats.packetsLost / stats.totalPackets || 0
  const { jitter } = stats
  const { rtt } = stats
  let score = 100
  score -= packetLossPercent * 50 > 50 ? 50 : packetLossPercent * 50
  score -= ((jitter / 30) * 25 > 25 ? 25 : (jitter / 30) * 25) || 0
  score -= ((rtt / 300) * 25 > 25 ? 25 : (rtt / 300) * 25) || 0
  return score / 10
}

export function formatAMPM(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes} ${ampm}`
  return strTime
}

export const trimSnackBarText = (text = '') => {
  const maxLength = 52

  return text.length > maxLength ? `${text.substr(0, maxLength - 5)}...` : text
}

export const nameTructed = (name, tructedLength) => {
  if (name?.length > tructedLength) {
    if (tructedLength === 15) {
      return `${name.substr(0, 12)}...`
    }
    return `${name.substr(0, tructedLength)}...`
  }
  return name
}

export const sideBarModes = {
  PARTICIPANTS: 'PARTICIPANTS',
  CHAT: 'CHAT',
}

export function debounce(func, wait, immediate) {
  let timeout

  return function executedFunction() {
    const context = this
    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
