import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import moment from 'moment'
import { usePerguntas } from '../../../hooks/usePerguntas'
import { toCurrency } from '../../../utils/toCurrency'
import { formatClasse } from '../../../utils/formatClasse'

const cores = {
  verde: '#00ff00',
  vermelho: '#ff0000',
  azul: '#0000ff',
  amarelo: '#ffff00',
  roxo: '#ff00ff',
  preto: '#000000',
  laranja: '#ffa500',
  cinza: '#808080',
  branco: '#ffffff',
}

moment.locale('pt-br')

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function ModalResultado({ perguntaId, isOpen, onClose }) {
  const {
    getAcompanhamentoTotalizar,
    getAcompanhamento,
    acompanhamentoTotal,
    acompanhamento,
  } = usePerguntas(perguntaId)

  useEffect(() => {
    let time
    if (isOpen) {
      getAcompanhamentoTotalizar()
      getAcompanhamento()
      time = setInterval(() => {
        getAcompanhamento()
        getAcompanhamentoTotalizar()
      }, 10000)
    }

    return () => {
      clearInterval(time)
    }
  }, [isOpen])

  const classes = acompanhamentoTotal?.classes ?? []

  const findResposta = (resposta) => {
    const resp = acompanhamentoTotal?.respostas.find(
      (t) =>
        String(t.resposta).toLowerCase().trim() ===
        String(resposta).toLowerCase().trim()
    )

    return resp
  }

  const getPorcentagem = (total, classe) => {
    const totalGeral = acompanhamentoTotal?.totalizador?.reduce((acc, curr) => {
      if (classe === curr.classe && !curr.abstencao) {
        return acc + curr.quantidade
      }
      return acc
    }, 0)
    return ((total ?? 0) / (totalGeral ?? 0) ?? 0) * 100
  }

  const respostas = (classe) =>
    acompanhamentoTotal?.totalizador
      ?.filter(
        (a) => formatClasse(a.classe) === formatClasse(classe) && !a.abstencao
      )
      ?.map((totalizador) => {
        const resp = findResposta(totalizador.resposta ?? '')

        return {
          classe: formatClasse(totalizador?.classe ?? ''),
          resposta: totalizador?.resposta,
          total: totalizador?.quantidade ?? 0,
          porcentagem:
            getPorcentagem(
              totalizador?.quantidade ?? 0,
              totalizador?.classe ?? ''
            ) ?? 0,
          bg: cores[resp?.cor_resposta],
        }
      })

  const getTotalVotos = acompanhamentoTotal?.totalizador.reduce((acc, curr) => {
    if (!curr.abstencao) {
      return acc + curr.quantidade
    }
    return acc
  }, 0)

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant='unstyled' size='4xl'>
      <ModalOverlay bg='blackAlpha.600' backdropFilter='blur(10px)' />
      <ModalContent bg='#EEEEEE'>
        <ModalHeader textAlign='center' fontSize='20px' fontWeight='normal'>
          Acompanhamento de Votação
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flex={1} flexDir='column'>
            <Box h='200px' overflowX='auto'>
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>nome</Th>
                      <Th>classe</Th>
                      <Th isNumeric>voto</Th>
                      <Th isNumeric>total</Th>
                      <Th isNumeric>hora</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {acompanhamento
                      ?.map((ac, index) => (
                        <Tr key={String(`${index}`)}>
                          <Td>{ac.credor}</Td>
                          <Td>{formatClasse(ac.classe)}</Td>
                          <Td isNumeric>{ac.resposta}</Td>
                          <Td isNumeric>{toCurrency(ac.valor)}</Td>
                          <Td isNumeric>
                            {moment(ac.data_votacao_resposta).format(
                              'HH:mm:ss - DD/MM/YY'
                            )}
                          </Td>
                        </Tr>
                      ))
                      .reverse()}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

            <Box py='20px'>
              <Text textAlign='center' fontSize='16px'>
                Votação: Totalizador por classes
              </Text>

              <Box pt='20px'>
                {classes.map((classe) => (
                  <HStack
                    py='20px'
                    w='full'
                    borderTop='1px solid #ddd'
                    key={classe}
                    spacing='20px'
                  >
                    <Text w='100px'>{formatClasse(classe)}</Text>

                    <HStack flex={1} spacing={0}>
                      {respostas(classe)?.map((resposta, idx) => (
                        <Box
                          key={`${idx}`}
                          bg={resposta.bg}
                          w={`${resposta.porcentagem}%`}
                        >
                          <Text
                            color='black'
                            fontSize='12px'
                            fontWeight='bold'
                            textAlign='center'
                            py='10px'
                          >
                            {resposta.resposta} -{' '}
                            {Math.round(resposta.porcentagem || 0)}%
                          </Text>
                        </Box>
                      ))}
                    </HStack>
                  </HStack>
                ))}
              </Box>

              <Stack
                align='center'
                fontWeight='bold'
                textAlign='center'
                borderTop='1px solid #ddd'
                pt='20px'
                fontSize='18px'
              >
                <Text>
                  Total de abstenções:{' '}
                  {acompanhamentoTotal?.totalizador.filter((a) => !!a.abstencao)
                    .length ?? 0}
                </Text>
                <Text>Total de votos: {getTotalVotos ?? 0}</Text>
              </Stack>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalResultado
