import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { api } from '../services/api'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const params = new URLSearchParams(window.location.search)
  const userId = params.get('userId')
  const callId = params.get('callId')
  const [user, setUser] = useState(null)
  const [profile, setProfile] = useState({})
  const isAdmin = profile?.perfil?.includes('aj')

  function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  const [token, refresh] = useMemo(() => {
    return [getCookie('token'), getCookie('refresh')]
  }, [document.cookie])

  useEffect(() => {
    if (token && refresh) {
      setTokenStorage(token, refresh)
      getUser()
    } else {
      sessionStorage.removeItem('@agc:auth')
    }
  }, [token, refresh, userId, callId])

  const setTokenStorage = (access, refresh) => {
    const user_id = params.get('userId')
    sessionStorage.setItem(
      '@agc:auth',
      JSON.stringify({
        access,
        refresh,
        user_id,
      })
    )
  }

  useEffect(() => {
    if (user?.id) {
      api.get(`/agc/${callId}/perfil-usuario-logado/`).then((res) => {
        setProfile(res.data)
      })
    }
  }, [user?.id])

  const getUser = () => {
    api.get(`/usuario/registro/${userId}`).then(({ data }) => {
      setUser(data)
    })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        callId,
        isAdmin,
        profile,
        getUser,
        setTokenStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
