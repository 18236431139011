import { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { api } from '../services/api'
import { useWebsocket } from './useWebsocket'

export const useAGC = (agcId) => {
  const { dispararPerguntas } = useWebsocket()
  const [votacaoIniciada, setVotacaoIniciada] = useState(false)
  const toast = useToast()

  const push = (path) => {
    window.location.href = path
  }

  const entrar = async () => {
    try {
      await api.post(`/agc/${agcId}/entrar/`)
    } catch (err) {
      push('https://app.e-agc.com.br/agcs')
      toast({
        title: 'Erro ao entrar na AGC',
        description: err.response.data?.detail,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const encerrar = async () => {
    try {
      await api.patch(`/agc/${agcId}/encerrar/`, {
        encerrada: true,
      })
    } catch (err) {
      toast({
        title: 'Erro ao entrar na AGC',
        description: err.response.data?.detail,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const desabilitarCredenciamento = async () => {
    try {
      await api.patch(`/agc/${agcId}/habilitar-credenciamento/`, {
        credenciamento_habilitado: false,
      })
    } catch (err) {
      toast({
        title: 'Erro ao entrar na AGC',
        description: err.response.data?.detail,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const iniciarVotacao = async (idPergunta) => {
    try {
      setVotacaoIniciada(!votacaoIniciada)
      dispararPerguntas(votacaoIniciada ? undefined : idPergunta)
      if (votacaoIniciada) {
        await api.post(`/agc-pergunta/${idPergunta}/encerrar-votacao/`)
      } else {
        await api.post(`/agc-pergunta/${idPergunta}/iniciar-votacao/`)
      }
    } catch (error) {
      //
    }
  }

  const listarVotantes = async (idPergunta) => {
    try {
      const res = await api.get(`agc-pergunta/${idPergunta}/listar-votantes/`)

      return res.data?.usuarios ?? []
    } catch (error) {
      return []
    }
  }

  return {
    entrar,
    encerrar,
    desabilitarCredenciamento,
    iniciarVotacao,
    listarVotantes,
    votacaoIniciada,
  }
}
