import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { memo, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { usePerguntas } from '../../../hooks/usePerguntas'
import RespostasCard from './respostas'
import { useAuth } from '../../../hooks/useAuth'
import { useClasses } from '../../../hooks/useClasse'
import InputComponent from '../../../components/input'

function CriarPerguntaModal({ isOpen, onClose }) {
  const { callId } = useAuth()
  const { classes, getClasse } = useClasses()
  const { criarPergunta, loading } = usePerguntas(Number(callId))
  const [typoDeClasse, setTipoDeClasse] = useState([])

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      respostas: [
        {
          resposta: 'Abstenção',
          cor_resposta: 'amarelo',
          abstencao: true,
        },
      ],
    },
  })

  useEffect(() => {
    if (isOpen) {
      formMethods.reset()
    }
  }, [isOpen])

  useEffect(() => {
    if (callId) {
      getClasse(callId)
    }
  }, [callId])

  const handleSubmit = async (data) => {
    await criarPergunta(
      {
        ...data,
        respostas: data.respostas.map((a) => ({
          ...a,
          cor_resposta: a.cor_resposta || 'verde',
        })),
      },
      typoDeClasse
    )
    onClose()
  }

  const PerguntasPadroes = [
    {
      descricao: 'Aprovação do plano?',
      respostas: [
        {
          resposta: 'Abstenção',
          cor_resposta: 'amarelo',
          abstencao: true,
        },
        {
          resposta: 'Sim',
          cor_resposta: 'verde',
        },
        {
          resposta: 'Não',
          cor_resposta: 'vermelho',
        },
      ],
      classes: classes.map((classe) => {
        if (
          classe.classe.includes('garantia-real') ||
          classe.classe.includes('quirografario')
        ) {
          return {
            agc_classe_id: classe.id,
            cabeca: true,
            credito: true,
          }
        }

        return {
          agc_classe_id: classe.id,
          cabeca: true,
          credito: false,
        }
      }),
    },
    {
      descricao: 'Suspensão da Assembleia?',
      respostas: [
        {
          resposta: 'Abstenção',
          cor_resposta: 'amarelo',
          abstencao: true,
        },
        {
          resposta: 'Sim',
          cor_resposta: 'verde',
        },
        {
          resposta: 'Não',
          cor_resposta: 'vermelho',
        },
      ],
      classes: classes.map((classe) => ({
        agc_classe_id: classe.id,
        cabeca: false,
        credito: true,
      })),
    },
    {
      descricao: 'Outros',
      respostas: [
        {
          resposta: 'Abstenção',
          cor_resposta: 'amarelo',
          abstencao: true,
        },
        {
          resposta: 'Sim',
          cor_resposta: 'verde',
        },
        {
          resposta: 'Não',
          cor_resposta: 'vermelho',
        },
      ],
    },
  ]

  return (
    <Modal
      useInert
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      variant='unstyled'
      size='lg'
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
          <ModalContent
            shadow='lg'
            h='100vh'
            borderRadius='0'
            pos='absolute'
            right={0}
            bg='#EEEEEE'
          >
            <ModalHeader fontWeight='normal' fontSize='24px' pt='30px'>
              Nova pergunta
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody flex={1} overflow='auto'>
              <Text fontSize='16px' fontWeight='400' pb='5px'>
                Perguntas padrões
              </Text>
              <Select
                bg='white'
                borderRadius='10px'
                placeholder='Perguntas padrões'
                size='lg'
                mb='20px'
                onChange={(e) => {
                  const pergunta = PerguntasPadroes.find(
                    (p) => p.descricao === e.target.value
                  )
                  if (pergunta) {
                    setTipoDeClasse(pergunta.classes || [])

                    formMethods.setValue('respostas', null)
                    formMethods.setValue('descricao', pergunta.descricao)
                    formMethods.setValue('respostas', pergunta.respostas)
                  }
                }}
              >
                {PerguntasPadroes.map((pergunta) => (
                  <option key={pergunta.descricao} value={pergunta.descricao}>
                    {pergunta.descricao}
                  </option>
                ))}
              </Select>

              <InputComponent
                name='descricao'
                label='Pergunta'
                placeholder='Texto da pergunta'
              />

              <Box pt='40px'>
                <HStack
                  justify='space-between'
                  fontWeight='bold'
                  fontSize='14px'
                  borderBottom='1px solid #ccc'
                  pb='5px'
                >
                  <Text>Classes votantes</Text>

                  <HStack spacing='20px'>
                    <Text>Cabeça</Text>
                    <Text>Crédito</Text>
                  </HStack>
                </HStack>

                {classes?.map((classe) => (
                  <HStack
                    pt='20px'
                    justify='space-between'
                    key={String(classe.id)}
                  >
                    <Text textTransform='capitalize'>{classe?.classe}</Text>

                    <HStack justify='center' spacing='20px'>
                      <Box w='43px'>
                        <Checkbox
                          isChecked={
                            !!typoDeClasse.find(
                              (item) =>
                                item.agc_classe_id === classe.id &&
                                item.cabeca === true
                            )
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTipoDeClasse((old) => [
                                ...old,
                                { agc_classe_id: classe.id, cabeca: true },
                              ])
                            } else {
                              setTipoDeClasse(
                                typoDeClasse.filter(
                                  (item) =>
                                    !(
                                      item.agc_classe_id === classe.id &&
                                      item.cabeca === true
                                    )
                                )
                              )
                            }
                          }}
                          _checked={{
                            span: { bg: '#3A4546', borderColor: '#3A4546' },
                          }}
                          iconColor='white'
                          bg='white'
                          size='lg'
                        />
                      </Box>
                      <Box w='43px'>
                        <Checkbox
                          isChecked={
                            !!typoDeClasse.find(
                              (item) =>
                                item.agc_classe_id === classe.id &&
                                item.credito === true
                            )
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTipoDeClasse((old) => [
                                ...old,
                                { agc_classe_id: classe.id, credito: true },
                              ])
                            } else {
                              setTipoDeClasse(
                                typoDeClasse.filter(
                                  (item) =>
                                    !(
                                      item.agc_classe_id === classe.id &&
                                      item.credito === true
                                    )
                                )
                              )
                            }
                          }}
                          _checked={{
                            span: { bg: '#3A4546', borderColor: '#3A4546' },
                          }}
                          iconColor='white'
                          bg='white'
                          size='lg'
                        />
                      </Box>
                    </HStack>
                  </HStack>
                ))}

                <RespostasCard />
              </Box>
            </ModalBody>

            <ModalFooter justifyContent='space-between'>
              <Button
                px='50px'
                variant='outline'
                borderColor='primary'
                borderWidth='2px'
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                isLoading={loading}
                color='white'
                px='70px'
                bg='primary'
              >
                Publicar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default memo(CriarPerguntaModal)
