import { usePubSub } from '@videosdk.live/react-sdk'
import React, { createRef, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import 'moment/locale/pt-br'
import moment from 'moment'
import { TbSend } from 'react-icons/tb'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { BiSolidUser } from 'react-icons/bi'

function ChatComponent() {
  const { publish, messages } = usePubSub('CHAT')
  const messagesEndRef = createRef()
  const [text, setText] = useState('')

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const handleSubmit = () => {
    publish(text, { persist: true })
    setText('')
  }

  return (
    <Flex flexDir='column' flex={1}>
      <Flex flexDir='column' overflow='auto' flex='1 1 0'>
        <Text p='30px' fontSize='18px'>
          Chat
        </Text>

        {messages.map((message, index) => (
          <Box key={String(`${index}as`)} pb='10px' px='30px'>
            <HStack>
              <Stack
                align='center'
                justify='center'
                w='30px'
                h='30px'
                borderRadius='50px'
                bg='gray.100'
              >
                <Icon as={BiSolidUser} color='gray.400' fontSize='22px' />
              </Stack>

              <Text fontSize='12px' fontWeight='bold'>
                {message.senderName}
              </Text>

              <Text opacity={0.5} fontSize='10px'>
                {moment(message.timestamp).format('HH[h]mm')}
              </Text>
            </HStack>

            <Box pl='38px' flex={1}>
              <Image src={message?.image} />
              {message?.doc && (
                <Flex
                  onClick={() => {
                    //
                  }}
                  cursor='pointer'
                  _hover={{ bg: 'gray.300' }}
                  p='8px 20px'
                  borderRadius='100px'
                  bg='gray.200'
                  w='250px'
                  align='center'
                >
                  <Text
                    flex={1}
                    opacity={0.6}
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    overflow='hidden'
                  >
                    enviou um documento
                  </Text>

                  <Icon as={AiOutlineCloudDownload} fontSize='22px' />
                </Flex>
              )}
              <Text lineHeight='16px'>{message?.message}</Text>
            </Box>
          </Box>
        ))}

        <div ref={messagesEndRef} />
      </Flex>

      <HStack spacing={0} px='20px'>
        {/* <IconButton
          aria-label='Enviar mensagem'
          bg='white'
          shadow='lg'
          size='sm'
          onClick={handleSubmit}
          icon={<Icon color='#DCAC36' as={BsEmojiSmile} fontSize='24px' />}
        >
          Enviar
        </IconButton> */}
        <Input
          px='14px'
          shadow='none'
          bg='transparent'
          value={text}
          onChange={(e) => {
            setText(e.target.value)
          }}
          placeholder='Mensagem'
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        />
        <HStack>
          {/* <Icon
            onClick={() => {
              //
            }}
            color='#AAAAAA'
            as={CgImage}
            fontSize='22px'
          />
          <Icon
            onClick={() => {
              //
            }}
            color='#AAAAAA'
            as={FiPaperclip}
            fontSize='22px'
          /> */}
          <IconButton
            size='md'
            bg='#DCAC36'
            aria-label='Enviar mensagem'
            onClick={handleSubmit}
            icon={<Icon color='white' as={TbSend} fontSize='26px' />}
            shadow='lg'
          />
        </HStack>
      </HStack>
    </Flex>
  )
}

export default ChatComponent
