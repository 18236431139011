export const trimSnackBarText = (text = '') => {
  const maxLength = 52

  return text.length > maxLength ? `${text.substr(0, maxLength - 5)}...` : text
}

export const nameTructed = (name, tructedLength) => {
  if (name?.length > tructedLength) {
    if (tructedLength === 15) {
      return `${name.substr(0, 12)}...`
    }
    return `${name.substr(0, tructedLength)}...`
  }
  return name
}

export const json_verify = (s) => {
  try {
    JSON.parse(s)
    return true
  } catch (e) {
    return false
  }
}

export function formatAMPM(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes} ${ampm}`
  return strTime
}
