import axios from 'axios'

export const api = axios.create({
  baseURL: 'https://admin.e-agc.com.br/api/v1',
})

api.interceptors.request.use(async (config) => {
  const authData = JSON.parse(sessionStorage.getItem('@agc:auth') ?? {})

  config.headers.Authorization = `Bearer ${authData?.access}`
  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const token = await refreshToken()
      api.defaults.headers.common.Authorization = `Bearer ${token}`
      return api(originalRequest)
    }
    return Promise.reject(error)
  }
)

const refreshToken = async () => {
  const AuthData = JSON.parse(sessionStorage.getItem('@agc:auth') ?? {})

  if (AuthData?.access) {
    const res = await api.post('/login/refresh/', { refresh: AuthData.refresh })
    if (res.status === 200) {
      const token = res.data.access
      api.defaults.headers.common.Authorization = `Bearer ${token}`

      const refresh = {
        access: token,
        refresh: res.data.refresh,
        user_id: AuthData.user_id,
      }
      sessionStorage.setItem('@agc:auth', JSON.stringify(refresh))
      return token
    }
  }
}
