import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { usePerguntas } from '../../hooks/usePerguntas'
import { useCredores } from '../../hooks/useCredores'
import CardCredorVotar from './CardCredorVotar'
import { useAuth } from '../../hooks/useAuth'

function ModalVotar({ isOpen, onClose, perguntaId }) {
  const { user, callId } = useAuth()

  const {
    getRespostasDasPerguntas,
    respostas,
    getPerguntas,
    perguntas,
    votarPergunta,
  } = usePerguntas(Number(callId))

  const pergunta = perguntas?.find((a) => a.id === Number(perguntaId))
  const { credorProcuradors, getAgcCredorProcurador } = useCredores(
    Number(callId)
  )

  const [votos, setVotos] = useState([])

  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (isOpen) {
      getAgcCredorProcurador(user.id)
      getPerguntas()
    }
  }, [isOpen, user.id])

  useEffect(() => {
    if (pergunta?.id) {
      getRespostasDasPerguntas(pergunta?.id)
    }
  }, [pergunta?.id])

  const handleConfirmarVotacao = async () => {
    try {
      setLoading(true)
      for (let i = 0; i < votos?.length; i++) {
        await votarPergunta(pergunta.id, votos[i].respostaId, votos[i].credorId)
      }
      toast({
        title: 'Voto realizado com sucesso',
        description: 'Seu voto foi computado',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      setLoading(false)
      onClose()
    } catch (error) {
      toast({
        title: 'Erro ao votar',
        description: 'Tente novamente mais tarde',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  const handleVoto = (credorId, respostaId) => {
    const prevVotes = votos.filter((item) => item.credorId !== credorId)
    setVotos([...prevVotes, { respostaId, credorId }])
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
      variant='unstyled'
      size='2xl'
    >
      <ModalOverlay bg='blackAlpha.600' backdropFilter='blur(10px)' />

      <ModalContent
        w='50vw'
        borderRadius='20px'
        pos='absolute'
        top='5vh'
        right='5vw'
        bg='#EEEEEE'
        h='90vh'
      >
        <ModalHeader textAlign='center' fontSize='20px' fontWeight='normal'>
          Defina seus <b>votos</b>
        </ModalHeader>

        <ModalBody>
          {credorProcuradors.map((credor, index) => (
            <CardCredorVotar
              respostas={respostas}
              onVote={handleVoto}
              credor={credor}
              key={String(`${index}123`)}
            />
          ))}
        </ModalBody>
      </ModalContent>

      <Flex
        flexDir='column'
        color='white'
        position='absolute'
        left='5vw'
        w='40vw'
        zIndex={10000}
        top={0}
        bottom={0}
        justify='center'
        gap='20px'
      >
        <HStack>
          <Text bg='primary' borderRadius='100px' p='10px 20px'>
            Pergunta
          </Text>
        </HStack>
        <Text fontSize='64px' fontWeight='black' lineHeight='64px'>
          {pergunta?.descricao}
        </Text>
        <Text>✓ Confirme seus votos assim que tiver certeza!</Text>

        <HStack pt='20px'>
          <Button
            fontWeight='bold'
            bg='primary'
            color='white'
            p='10px 30px'
            fontSize='16px'
            onClick={handleConfirmarVotacao}
            isLoading={loading}
          >
            Confirmar Votação
          </Button>

          <Button
            onClick={onClose}
            fontWeight='bold'
            borderColor='primary'
            bg='transparent'
            color='white'
            p='10px 30px'
            fontSize='16px'
            borderWidth='2px'
          >
            Cancelar
          </Button>
        </HStack>
      </Flex>
    </Modal>
  )
}

export default ModalVotar
