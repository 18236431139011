import { useState } from 'react'
import { api } from '../services/api'

export const usePerguntas = (agcId) => {
  const [loading, setLoading] = useState(false)
  const [perguntas, setPerguntas] = useState([])
  const [totalVotos, setTotalVotos] = useState(0)
  const [acompanhamento, setAcompanhamento] = useState()
  const [respostas, setRespostas] = useState([])

  const [acompanhamentoTotal, setAcompanhamentoTotal] = useState()

  const votarPergunta = async (idPergunta, idResposta, credorId) => {
    try {
      await api.post('agc-pergunta-votacao-resposta/', {
        agc_pergunta_id: idPergunta,
        agc_pergunta_resposta_id: idResposta,
        agc_credor_procurador_id: credorId,
        observacao: '',
      })
    } catch (error) {
      throw new Error('Erro ao votar na pergunta')
    }
  }

  const getAcompanhamento = async () => {
    try {
      const res = await api.get(`/agc-pergunta/${agcId}/acompanhamento/`, {
        params: { agc_pergunta_id: agcId },
      })
      setAcompanhamento(res.data ?? [])
    } catch (error) {
      //
    }
  }

  const getAcompanhamentoTotalizar = async () => {
    try {
      const res = await api.get(
        `/agc-pergunta/${agcId}/acompanhamento-totalizar/`
      )
      setAcompanhamentoTotal(res.data)
    } catch (error) {
      //
    }
  }

  const getTotalVotos = async () => {
    try {
      const res = await api.get(`/agc-pergunta/${agcId}/total-votacao/`, {
        params: { id: agcId },
      })
      setTotalVotos(res.data?.quantidade ?? 0)
    } catch (error) {
      //
    }
  }

  const getPerguntas = async () => {
    try {
      setLoading(true)
      const res = await api.get('/agc-pergunta/', { params: { agc_id: agcId } })
      setPerguntas(res.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const criarPergunta = async (data, classes) => {
    try {
      setLoading(true)
      const res = await api.post('/agc-pergunta/', {
        ...data,
        agc_id: agcId,
      })

      await Promise.allSettled(
        data.respostas.map((resposta) =>
          api.post('/agc-pergunta-resposta/', {
            ...resposta,
            agc_pergunta_id: res.data.id,
          })
        )
      )

      await Promise.allSettled(
        classes.map((classe) =>
          api.post('/agc-pergunta-classe/', {
            ...classe,
            agc_pergunta_id: res.data.id,
          })
        )
      )

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getRespostasDasPerguntas = async (idPergunta) => {
    try {
      const res = await api.get('agc-pergunta-resposta/', {
        params: { agc_pergunta_id: idPergunta },
      })
      setRespostas(res.data.results ?? [])
    } catch (error) {
      //
    }
  }

  return {
    getRespostasDasPerguntas,
    respostas,
    loading,
    criarPergunta,
    votarPergunta,
    getPerguntas,
    perguntas,
    totalVotos,
    getTotalVotos,
    acompanhamento,
    getAcompanhamentoTotalizar,
    acompanhamentoTotal,
    getAcompanhamento,
  }
}
