import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { ToastContainer } from 'react-toastify'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from './agc/hooks/useAuth'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthProvider>
    <ToastContainer
      toastClassName={() =>
        'relative flex py-4 px-3 rounded overflow-hidden cursor-pointer bg-white shadow-lg'
      }
      bodyClassName={() => 'text-black text-base font-normal'}
      position='bottom-left'
      autoClose={4000}
      hideProgressBar
      newestOnTop={false}
      closeButton={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='light'
    />
    <App />
  </AuthProvider>
)
