import React, { createContext, useContext, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

const DialogContext = createContext(null)

export function DialogProvider({ children }) {
  const [show, setShow] = useState(false)
  const [alert, setAlert] = useState(null)

  const showDialog = (dialogData) => {
    setAlert(dialogData)
    setShow(true)
  }

  return (
    <DialogContext.Provider value={showDialog}>
      {children}
      <Modal isCentered onClose={() => setShow(false)} isOpen={show}>
        <ModalOverlay bg='blackAlpha.400' backdropFilter='blur(10px)' />
        <ModalContent>
          <ModalHeader>{alert?.title ?? ''}</ModalHeader>
          <ModalCloseButton />
          <ModalBody mt={-3}>
            <Text>{alert?.message ?? ''}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant='text_secondary'
              mr={3}
              size='sm'
              onClick={() => setShow(false)}
            >
              {alert?.buttonCancel ?? 'Cancelar'}
            </Button>

            <Button
              size='sm'
              variant='solid_secondary'
              onClick={() => {
                alert?.onClick()
                setShow(false)
              }}
            >
              {alert?.buttonOK ?? 'Salvar'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DialogContext.Provider>
  )
}

export const useDialog = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('Cant use Alert context')
  }
  return context
}
