import React from 'react'
import { Box, Center, Divider, Flex, Text } from '@chakra-ui/react'
import { useMeeting } from '@videosdk.live/react-sdk'
import UserCard from './UserCard'
import UserCardRequest from './UserCardRequest'
import { useMeetingAppContext } from '../../../MeetingAppContextDef'
import ChatComponent from './Chat'
import { useAuth } from '../../hooks/useAuth'

function PeopleAndChat() {
  const { isAdmin } = useAuth()
  const { raisedHandsParticipants } = useMeetingAppContext()

  const mMeeting = useMeeting({
    onMicRequested: ({ accept }) => {
      accept()
    },
    onWebcamRequested: ({ accept }) => {
      accept()
    },
  })

  const currentUser = mMeeting.localParticipant

  const participants = [...mMeeting.participants.values()]

  const requestedMicrophones = participants.filter(
    (user) =>
      !!raisedHandsParticipants.find(
        ({ participantId }) => participantId === user.id
      ) && user.id !== currentUser.id
  )

  return (
    <Flex
      flexDirection='column'
      bg='white'
      borderRight='1px solid #ddd'
      h='calc(100vh - 70px)'
      w='full'
      maxW='400px'
      shadow='0px 15px 30px 0px rgba(0, 0, 0, 0.1)'
      zIndex={1000}
    >
      <Flex flexDir='column' flex={1}>
        <Flex flexDir='column' p='30px' overflow='auto' flex='1 1 0'>
          {isAdmin && (
            <Box position='relative'>
              <Text fontSize='18px' pb='20px'>
                Lista de espera para falar
              </Text>

              {requestedMicrophones?.length === 0 && (
                <Center pb='20px' opacity={0.5}>
                  <Text>Nenhum participante na lista de espera</Text>
                </Center>
              )}

              {requestedMicrophones?.map((user, index) => (
                <UserCardRequest
                  index={index}
                  user={user}
                  key={String(user.userId)}
                />
              ))}

              <Divider mb='10px' />
            </Box>
          )}

          <Text fontSize='18px' pb='20px'>
            Pessoas
          </Text>

          {participants?.length === 0 && (
            <Center pb='50px' opacity={0.5}>
              <Text>Nenhum participante</Text>
            </Center>
          )}

          <Flex flexDir='column' flex={1}>
            {participants?.map((user) => (
              <UserCard isAdmin user={user} key={String(user.id)} />
            ))}
          </Flex>
        </Flex>

        <Divider borderColor='#ddd' borderWidth='1px' />

        <Flex flexDir='column' flex={1.4}>
          <ChatComponent />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PeopleAndChat
