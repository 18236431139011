import React from 'react'

export function MeetingDetailsScreen({ setStatarted }) {
  return (
    <div className='flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5'>
      <div className='w-full md:mt-0 mt-4 flex flex-col'>
        <div className='flex items-center justify-center flex-col w-full '>
          <button
            className='w-full bg-yellow-150 text-white px-2 py-3 rounded-xl'
            onClick={async () => {
              setStatarted(true)
            }}
          >
            Entrar na AGC
          </button>
        </div>
      </div>
    </div>
  )
}
