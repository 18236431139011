import { useState } from 'react'
import { api } from '../services/api'

export const useClasses = () => {
  const [loading, setLoading] = useState(false)
  const [classes, stClasses] = useState([])

  const getClasse = async (callId) => {
    try {
      setLoading(true)
      const res = await api.get('/agc-classe', {
        params: { agc_id: callId },
      })
      stClasses(res.data?.results ?? [])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return { getClasse, classes, loading }
}
