import { useState } from 'react'
import { api } from '../services/api'

export const useDocs = (agcId) => {
  const [loading, setLoading] = useState(true)
  const [docs, setDocs] = useState([])

  const getDocs = async () => {
    try {
      setLoading(true)
      const res = await api.get('/agc-documento/', {
        params: { agc_id: agcId },
      })
      setDocs(res.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return { getDocs, loading, docs }
}
