import { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { api } from '../services/api'

export const useCredores = (agcId) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  })
  const toast = useToast()
  const [credores, setCredores] = useState([])
  const [credoresHabilitados, setCredoresHabilitados] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [credenciamento, setCredenciamento] = useState([])
  const [credorProcuradors, setCredorProcuradors] = useState([])

  const updateCredor = async (credor) => {
    try {
      setLoading(true)
      await api.put(`agc-credor/${credor.id}`, {
        nome: credor.nome,
        tipo_documento: credor.tipo_documento,
        documento: credor.documento,
        agc_classe_id: credor.agc_classe_id,
        moeda: credor.moeda,
        valor_original: credor.valor_original,
        procuradores: credor.procuradores,
      })
      await getCredores()
      setLoading(false)
      toast({
        title: 'Credor atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Erro ao atualizar credor!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  const getOffset = (page, limit) => {
    return page * limit - limit
  }

  const getCredores = async () => {
    try {
      setLoading(true)
      const res = await api.get('agc-credor', {
        params: {
          agc_id: agcId,
          limit: filters?.limit ?? 10,
          offset: getOffset(filters?.page ?? 0, filters?.limit ?? 10),
          search: filters?.search ?? '',
          usuario_id: filters?.usuario_id,
        },
      })
      setCount(res.data?.count ?? 0)
      setCredores(res.data?.results ?? [])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteCredor = async (id) => {
    try {
      setLoading(true)
      await api.delete(`agc-credor/${id}`)
      await getCredores()
      setLoading(false)
    } catch (error) {
      toast({
        title: 'Erro ao deletar credor!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  const getCredenciamento = async () => {
    try {
      const res = await api.get(`/agc/${agcId}/acompanhamento-credenciamento/`)
      setCredenciamento(res.data)
    } catch (error) {
      //
    }
  }

  const getAcompanhamentoCredenciamento = async () => {
    try {
      const res = await api.get(
        `/agc/${agcId}/acompanhamento-credenciamento-totalizar/`
      )
      setCredenciamento(res.data)
    } catch (error) {
      //
    }
  }

  const getAgcCredorProcurador = async (userId) => {
    try {
      const res = await api.get('/agc-credor-procurador/', {
        params: {
          agc_id: agcId,
          usuario_id: userId,
          credenciado: true,
          habilitado: true,
        },
      })
      setCredorProcuradors(res.data.results)
    } catch (error) {
      //
    }
  }

  const getCredoresHabilitados = async () => {
    try {
      setLoading(true)
      const res = await api.get('/agc-credor/credores-habilitados/', {
        params: {
          agc_id: agcId,
        },
      })
      setCredoresHabilitados(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return {
    getCredoresHabilitados,
    filters,
    setFilters,
    getAcompanhamentoCredenciamento,
    getAgcCredorProcurador,
    credenciamento,
    credorProcuradors,
    updateCredor,
    getCredores,
    deleteCredor,
    credores,
    loading,
    count,
    credoresHabilitados,
    getCredenciamento,
  }
}
