import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { api } from '../services/api'

export const useSuspenderAGC = () => {
  const toast = useToast()
  const [suspensao, setSuspensa] = useState([])

  const getSuspensao = async (agcId) => {
    try {
      const res = await api.get('agc-suspensao', {
        params: { agc_id: agcId },
      })
      setSuspensa(res.data?.results)
    } catch (error) {
      //
    }
  }

  const suspenderAgc = async (data) => {
    try {
      await api.post('/agc-suspensao/', data)
    } catch (error) {
      toast({
        title: 'Erro ao suspender agc',
        description: 'Tente novamente mais tarde',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return { suspensao, getSuspensao, suspenderAgc }
}
