import React from 'react'
import { Icon, Flex, Text, Stack } from '@chakra-ui/react'
import { BsCheck2All } from 'react-icons/bs'
import { MdOutlineCoPresent } from 'react-icons/md'
import { FaRegAddressCard } from 'react-icons/fa'
import { TbUsersGroup } from 'react-icons/tb'

function LeftMenu({ tab, setTab }) {
  return (
    <Flex bg='white' h='calc(100vh - 70px)' w='100px' maxW='100px' zIndex={1}>
      <Stack spacing={0}>
        {[
          {
            icon: MdOutlineCoPresent,
            title: 'Principal',
            name: 'principal',
          },
          {
            icon: FaRegAddressCard,
            title: 'Painel de Credenciamento',
            name: 'credenciamento',
          },
          {
            icon: BsCheck2All,
            title: 'Painel de Votações',
            name: 'votacao',
          },
          {
            icon: TbUsersGroup,
            title: 'Credores',
            name: 'credores',
          },
          {
            icon: MdOutlineCoPresent,
            title: 'Documentos Importantes',
            name: 'docs',
          },
        ].map((item, index) => {
          const active = item.name === tab
          return (
            <Stack
              userSelect='none'
              _hover={{
                bg: '#ddd',
                cursor: 'pointer',
              }}
              transition='all 0.2s ease-in-out'
              onClick={() => {
                setTab(item.name)
              }}
              key={String(`${index}a`)}
              bg={active ? '#FDCA5F' : 'white'}
              color={active ? '#000000' : '#222222'}
              align='center'
              py='20px'
              spacing={0}
            >
              <Icon as={item.icon} fontSize='26px' />
              <Text textAlign='center' fontSize='11px' px='5px'>
                {item.title}
              </Text>
            </Stack>
          )
        })}
      </Stack>
    </Flex>
  )
}

export default LeftMenu
