// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react'
import { colors } from './colors'
import { fonts } from './fonts'
import { inputStyle } from './components/input'
import { buttonStyle } from './components/button'

const breakpoints = {
  sm: '40em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
}

export const theme = extendTheme({
  colors,
  fonts,
  breakpoints,
  components: {
    Input: inputStyle,
    Button: buttonStyle,
  },
})
