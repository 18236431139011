import React from 'react'
import { Button, HStack, Icon, Image } from '@chakra-ui/react'
import { MdOutlineLogout } from 'react-icons/md'
import { useMeeting } from '@videosdk.live/react-sdk'
import { useAGC } from '../../hooks/useAGC'

function HeaderAGC({
  callId,
  isAdmin,
  onSuspenderModalOpen,
  isMeetingStarted,
  setIsMeetingLeft,
}) {
  const mMeeting = useMeeting()
  const { encerrar } = useAGC(Number(callId))

  const deslogarTodos = async () => {
    await encerrar()
    mMeeting.end()
    setIsMeetingLeft(true)
  }

  const sairDaAssembleia = () => {
    mMeeting.leave()
    setIsMeetingLeft(true)
  }

  return (
    <HStack
      bg='#F2F2F2'
      px='50px'
      h='70px'
      w='full'
      shadow='lg'
      zIndex={10}
      pos='relative'
      justify='space-between'
    >
      <Image src={require('./logo.png')} w='53px' h='53px' />

      <HStack display={isMeetingStarted ? 'flex' : 'none'}>
        {isAdmin && (
          <>
            <Button
              rightIcon={<Icon as={MdOutlineLogout} fontSize='20px' />}
              fontSize='16px'
              h='44px'
              borderRadius='10px'
              bg='white'
              onClick={onSuspenderModalOpen}
            >
              Suspender AGC
            </Button>
            <Button
              rightIcon={<Icon as={MdOutlineLogout} fontSize='20px' />}
              fontSize='16px'
              h='44px'
              borderRadius='10px'
              bg='white'
              onClick={deslogarTodos}
            >
              Encerrar AGC
            </Button>
          </>
        )}
        <Button
          rightIcon={<Icon as={MdOutlineLogout} fontSize='20px' />}
          fontSize='16px'
          h='44px'
          borderRadius='10px'
          bg='white'
          onClick={sairDaAssembleia}
        >
          Sair da assembleia
        </Button>
      </HStack>
    </HStack>
  )
}

export default HeaderAGC
