import { useContext, createContext, useState, useEffect, useRef } from 'react'

export const MeetingAppContext = createContext()

export const useMeetingAppContext = () => useContext(MeetingAppContext)

export function MeetingAppProvider({ children }) {
  const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([])
  const [sideBarMode, setSideBarMode] = useState(null)
  const [pipMode, setPipMode] = useState(false)

  const useRaisedHandParticipants = () => {
    const raisedHandsParticipantsRef = useRef()

    const participantRaisedHand = (participantId) => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current]

      const newItem = { participantId, raisedHandOn: new Date().getTime() }

      const participantFound = raisedHandsParticipants.findIndex(
        ({ participantId: pID }) => pID === participantId
      )

      if (participantFound === -1) {
        raisedHandsParticipants.push(newItem)
      } else {
        raisedHandsParticipants[participantFound] = newItem
      }

      setRaisedHandsParticipants(raisedHandsParticipants)
    }

    useEffect(() => {
      raisedHandsParticipantsRef.current = raisedHandsParticipants
    }, [raisedHandsParticipants])

    const removeParticipantRaisedHand = (userId) => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current]

      const participantFound = raisedHandsParticipants.findIndex(
        ({ participantId }) => participantId === userId
      )

      if (participantFound !== -1) {
        raisedHandsParticipants.splice(participantFound, 1)
      }

      setRaisedHandsParticipants(raisedHandsParticipants)
    }

    return { participantRaisedHand, removeParticipantRaisedHand }
  }

  return (
    <MeetingAppContext.Provider
      value={{
        // states

        raisedHandsParticipants,

        sideBarMode,
        pipMode,
        // setters

        setRaisedHandsParticipants,

        setSideBarMode,
        setPipMode,
        useRaisedHandParticipants,
      }}
    >
      {children}
    </MeetingAppContext.Provider>
  )
}
