import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

import TodosCredores from './components/tabs/todos-credores'
import CredoresCredenciados from './components/tabs/credores-credenciados'
import CredoresHabilitados from './components/tabs/credores-habilitados'
import CredoresOnline from './components/tabs/credores-online'

export function CredoresModal({ isOpen, onClose }) {
  return (
    <Tabs variant='soft-rounded' colorScheme='green'>
      <Modal isOpen={isOpen} onClose={onClose} variant='unstyled' size='6xl'>
        <ModalOverlay bg='blackAlpha.600' backdropFilter='blur(10px)' />
        <ModalContent bg='#EEEEEE'>
          <ModalHeader>
            <TabList>
              <Tab>Todos os credores</Tab>
              <Tab>Credores habilitados</Tab>
              <Tab>Credores credenciados</Tab>
              <Tab>Credores online</Tab>
            </TabList>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TabPanels>
              <TabPanel>
                <TodosCredores isOpen={isOpen} />
              </TabPanel>
              <TabPanel>
                <CredoresHabilitados isOpen={isOpen} />
              </TabPanel>
              <TabPanel>
                <CredoresCredenciados isOpen={isOpen} />
              </TabPanel>
              <TabPanel>
                <CredoresOnline isOpen={isOpen} />
              </TabPanel>
            </TabPanels>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Tabs>
  )
}
