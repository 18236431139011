import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme'
import { useAuth } from './agc/hooks/useAuth'
import { DialogProvider } from './agc/hooks/useDialog'
import { WebsocketProvider } from './agc/hooks/useWebsocket'
import VideoSDKAPP from './VideoSDK'

function App() {
  const { callId } = useAuth()

  return (
    <ChakraProvider theme={theme}>
      <DialogProvider>
        <WebsocketProvider callId={callId}>
          <VideoSDKAPP />
        </WebsocketProvider>
      </DialogProvider>
    </ChakraProvider>
  )
}

export default App
