/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
} from '@chakra-ui/react'

function PdfViewerModal({ pdfUrl }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen}>Visualizar PDF</Button>

      <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Visualizar PDF</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display='flex' justifyContent='center'>
              <iframe
                src={pdfUrl}
                style={{ width: '100%', height: '65vh' }}
                frameBorder='0'
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PdfViewerModal
