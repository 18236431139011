import { Box, Button, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { useParticipant, usePubSub } from '@videosdk.live/react-sdk'
import React from 'react'
import { BiSolidUser } from 'react-icons/bi'
import { useMeetingAppContext } from '../../../../MeetingAppContextDef'

function UserCardRequest({ user, index }) {
  const { useRaisedHandParticipants } = useMeetingAppContext()
  const { removeParticipantRaisedHand } = useRaisedHandParticipants()
  const { enableMic } = useParticipant(user.id)

  const { publish } = usePubSub('RAISE_HAND_ACTIVES', {
    onMessageReceived: (data) => {
      const userId = data.message.participantId
      removeParticipantRaisedHand(userId)
    },
  })

  const handleEnableMic = () => {
    enableMic()
    publish({
      type: 'RAISE_HAND_ACTIVES',
      participantId: user.id,
    })
  }

  return (
    <HStack
      pb='10px'
      key={String(user.userId)}
      justify='space-between'
      spacing='14px'
    >
      <Stack
        align='center'
        justify='center'
        w='45px'
        h='45px'
        borderRadius='50px'
        bg='gray.100'
      >
        <Icon as={BiSolidUser} color='gray.400' fontSize='22px' />
      </Stack>

      <Box flex={1}>
        <Text fontSize='12px' fontWeight='bold' color='rgba(223, 145, 38, 1)'>
          {index + 1}° lugar na fila
        </Text>
        <Text fontSize='14px'>{user?.displayName}</Text>
      </Box>

      <Button
        color='white'
        fontSize='12px'
        p='0px 20px'
        h='34px'
        bg='#DCAC36'
        borderRadius='40px'
        onClick={() => {
          handleEnableMic()
          removeParticipantRaisedHand(user.id)
        }}
      >
        Autorizar
      </Button>
    </HStack>
  )
}

export default UserCardRequest
